.ant-tooltip {
  color: var(--text-color-1);
  max-width: 320px;
}

.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: var(--text-color-2);
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: var(--gray-3);
  border-radius: 6px;
  box-shadow: none;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, var(--gray-3), var(--gray-3));
}

.ant-tooltip-arrow-content::before {
  background-color: var(--gray-3);
}
