.personalLayout {
  position: relative;
  padding-left: 300px;
  padding-top: 110px;
  background: var(--admin-body-bg);

  &__wrapper {
    position: relative;

    .personalLayoutContent {
      margin-bottom: 40px;

      .container {
        display: block;
        width: 100%;
        max-width: 1472px;
        margin: 0 auto;
        padding: 0 80px;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .personalLayout {
    padding-left: 0;
  }
}

@media screen and (max-width: 1900px) {
  .personalLayout {
    &__wrapper {
      .personalLayoutContent {
        .container {
          padding: 0 16px;
        }
      }
    }
  }
}
