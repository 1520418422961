.header {
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--header-shadow);
  z-index: 200;
  background: var(--body-bg);

  .headerWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;

    .leftPart {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .logo {
        color: var(--primary);
        font-weight: 600;
        font-size: 32px;
        line-height: 1;
      }

      .pageTitle {
        margin: 0;
        padding: 12px 14px;
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.28px;
        text-transform: uppercase;
        color: white;
        white-space: nowrap;
      }

      .logo {
        margin-right: 10px;

        a {
          display: block;

          svg {
            width: 40px;
            height: 48px;
          }
        }
      }

      .menu {
        > ul {
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          > li {
            position: relative;

            > a {
              position: relative;
              display: block;
              padding: 12px 14px;
              font-size: 14px;
              font-weight: 700;
              line-height: 20px;
              letter-spacing: 0.28px;
              text-transform: uppercase;
              color: var(--text-color-1);
              white-space: nowrap;

              &:after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -2px;
                width: 4px;
                height: 4px;
                background: var(--primary);
                border-radius: 50%;
              }

              &:hover {
                color: var(--primary);
              }
            }

            &:last-child > a {
              padding-right: 0;

              &:after {
                display: none;
              }
            }

            > ul {
              margin: 0;
              list-style: none;
              display: none;
              position: absolute;
              width: 322px;
              padding: 16px;
              background: var(--dropdown-menu-bg);
              top: 100%;
              border-radius: 6px;
              transition: var(--default-transition);
              box-shadow: var(--header-shadow);
              z-index: 99;

              li {
                a {
                  display: block;
                  width: 100%;
                  padding: 8px;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 24px;
                  color: var(--text-color-2);
                  border-radius: 6px;
                  transition: var(--default-transition);

                  sup {
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--success);
                    margin-left: 4px;
                  }

                  &:hover {
                    background: var(--gray-1);
                    color: var(--text-color-1);
                  }
                }
              }
            }

            &:hover > ul {
              display: block;
              transition: var(--default-transition);
            }
          }
        }
      }
    }

    .search {
      width: 100%;
    }

    .rightPart {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;

      .homeBtn {
        margin: 0 16px;
      }
      .userBtn {
      }

      .themeBtn {
      }

      .addListingBtn {
      }

      .searchBtn {
        display: none;
      }

      .mobileMenuBtn {
        display: none;
      }

      .sellButton {
        display: none;
      }
    }
  }
}

.internationalHeader {
  box-shadow: none;
  background: transparent;
  position: absolute;
  .headerWrapper {
    .rightPart {
      .searchBtn {
        display: none !important;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .header {
    .headerWrapper {
      .leftPart {
        .menu {
          display: none;
        }
      }

      .rightPart {
        .addListingBtn {
          order: -1;
        }

        .themeBtn {
          display: none;
        }

        .searchBtn {
          display: flex;
        }

        .mobileMenuBtn {
          display: flex;
        }

        .sellButton {
          display: none !important;
        }
      }
    }
  }
  .internationalHeader {
    .headerWrapper {
      .rightPart {
        .homeBtn {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: 64px;
    padding: 0 12px;
  }
}

@media screen and (max-width: 590px) {
  .header {
    .headerWrapper {
      .rightPart {
        .addListingBtn {
          display: none;
        }

        .userBtn {
          display: none;
        }

        .sellButton {
          display: flex;
        }
      }
    }
  }
  .internationalHeader {
    .headerWrapper {
      .rightPart {
        .homeBtn {
          display: none;
        }
      }
    }
  }
}
