.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  height: 100%;
  background-color: var(--admin-sidebar-bg);
  z-index: 100;

  &__wrapper {
    padding-top: 120px;

    > ul {
      padding: 0;
      margin: 0;
      list-style: none;

      > li {
        margin-bottom: 20px;

        > a {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 2px 32px;
          transition: all 0.3s ease-in-out;

          .icon {
            margin-right: 12px;
            line-height: 1;

            svg {
              margin-top: -2px;
            }
          }

          .icon[data-type='marketer'] {
            svg {
              margin-top: -4px;
            }
          }

          .name {
            color: var(--text-color-1);
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0;
            text-align: left;
            transition: all 0.3s ease-in-out;
            position: relative;

            span {
              position: absolute;
              top: -12px;
              right: -36px;
              font-size: 12px;
              line-height: 1;
              font-weight: 500;
              color: #fff;
              background: var(--success);
              border-radius: 4px;
              padding: 2px 4px;
            }
          }

          .arrow {
            margin-left: auto;

            button {
              padding: 0;
              margin: 0;
              box-shadow: none;
              border: 0;
              background: transparent;

              svg {
                width: 14px;
                height: 14px;
                transform: rotate(-90deg);
                transition: all 0.2s ease-in-out;
              }
            }
          }

          .arrow.arrow__active button svg {
            transform: rotate(90deg);
            transition: all 0.2s ease-in-out;
          }

          &:hover .name {
            color: var(--primary);
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        > ul {
          height: 0;
          overflow: hidden;
          padding: 0;
          margin: 0;
          list-style: none;
          transition: all 0.3s ease;

          > li {
            margin-bottom: 12px;

            &:first-child {
              margin-top: 12px;
            }

            &:last-child {
              margin-bottom: 0;
            }

            > a {
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              padding: 0 32px 0 68px;
              color: var(--text-color-2);
              transition: all 0.3s ease;

              &:hover {
                color: var(--primary);
              }
            }
          }
        }

        > ul.active {
          height: auto;
          transition: all 0.15s ease-in-out;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .sidebar {
    left: -280px;
  }
}
