.service-amount-radio-group {
  .ant-radio-button-wrapper {
    margin-right: 12px;
    margin-bottom: 12px;
    position: relative;
    display: inline-block;
    height: auto;
    padding: 8px 16px;
    color: var(--text-color-1);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    background: var(--gray-border);
    border: 2px solid var(--gray-border) !important;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: none !important;
    transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper-checked {
    color: var(--primary) !important;
    background: var(--body-bg) !important;
    border: 2px solid var(--primary) !important;
  }
}

.auth-form {
  .service-amount-radio-group .ant-radio-button-wrapper {
    background: var(--gray-3);
    border: 1px solid var(--gray-border) !important;
    margin-bottom: 0;
  }

  .ant-radio-button-wrapper-checked:not(
      [class*=' ant-radio-button-wrapper-disabled']
    ).ant-radio-button-wrapper:first-child {
    border: 1px solid var(--primary) !important;
  }
}
