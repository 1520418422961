.ant-modal-content {
  background-color: var(--body-bg);
  border-radius: 20px;
  box-shadow: var(--header-shadow);
}

.ant-modal-mask {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(6px);
}

.ant-modal-body {
  padding: 40px 32px 32px;
}

.ant-modal {
  animation-duration: 300ms;
}

.basic-modal {
  .ant-modal-close-x {
    .ant-modal-close-icon {
      color: var(--text-color-1);
    }
  }
}

.basic-modal__fullwidth {
  padding: 0 !important;
  margin-bottom: 0 !important;
  max-width: 100%;

  .ant-modal-content {
    border-radius: 0;
  }

  .ant-modal-body {
    min-height: calc(100vh);
  }
}

.basic-modal.basic-modal__nopaddings {
  .ant-modal-body {
    padding: 12px;
  }
}

.basic-modal_services-modal {
  margin: 0;

  .ant-modal-close-x {
    svg {
      width: 36px;
      height: 36px;
    }
  }

  .ant-modal-body {
    padding: 0;
  }
}

.listing-modal {
  .ant-modal-body {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  .listing-modal__extended-close {
    .ant-modal-close {
      svg {
        width: 36px;
        height: 36px;
      }
    }
  }
}
