.ant-popover {
  position: fixed;
  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }
  }
}

//@media screen and (max-width: 768px) {
//  .ant-popover {
//  }
//}
