.container {
  padding: 0;
}

.container.container__banner {
  max-width: 1600px;
}

.container__video-promotion {
  display: block;
  width: 100%;
  max-width: 970px;
  margin: 0 auto;
}

.container-fluid__limited {
  max-width: 100%;
  padding: 0 40px;
}

.container-column {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.container-column-less-gap {
  gap: 40px;
}

@media (max-width: 1024px) {
  .container-column {
    gap: 60px;
  }
}

@media (min-width: 576px) {
  .container {
    max-width: 100%;
    padding: 20px;
  }
}

@media screen and (max-width: 576px) {
  .container {
    padding: 0 20px;
    max-width: 100%;
  }
  .container-fluid__limited {
    padding: 0 20px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    padding: 24px;
    max-width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    padding: 0;
    max-width: 840px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media screen and (min-width: 1400px) {
  .container {
    max-width: 1312px;
  }
}

@media screen and (max-width: 1024px) {
  .container__banner_nopad {
    padding: 0 !important;
  }
}
