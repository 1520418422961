.label {
  position: absolute;
  padding: 6px 8px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  color: #fff;
}

.boost-labels {
  position: absolute;
  top: 8px;
  left: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0;
  text-align: left;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.boost-labels .label {
  position: relative;
}

.topleft12 {
  top: 12px;
  left: 12px;
}

.topleft20 {
  top: 20px;
  left: 20px;
}

.showcase {
  // border: 1px solid rgba(255, 255, 255, 0.4);
  background: linear-gradient(90deg, #a32eff 1.81%, #433fff 100%);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  text-transform: uppercase;

  svg {
    margin-top: -2px;
    margin-right: 4px;
  }
}

.featured {
  background: #ff9f2e;
  color: #fff;
}

.auction,
.OFD {
  background: #fff;
  color: #000;
}

.OFD {
  font-weight: 400;
}
