.ant-select {
  transition: none !important;
  color: var(--text-color-1);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
}

.ant-select-selector {
  padding: 0 12px !important;
}

.ant-select-selection-item {
  padding-right: 8px;
}

.ant-select-arrow {
  transition: var(--default-transition);
  svg {
    color: var(--text-color-1) !important;
  }
}

.ant-select-dropdown {
  background: var(--gray-3);
}

.ant-select-item {
  color: var(--text-color-1);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: var(--gray-2);
  color: var(--text-color-2);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: var(--gray-1);
}

.properties-admin-form .simple-select.ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    padding: 7px 16px !important;
    height: auto;
  }
}

.payment-method-select {
  .ant-select-selection-item {
    margin-top: -7px;
  }
}

.ant-select-open {
  .ant-select-arrow {
    transition: var(--default-transition);
    transform: rotate(180deg);
  }
}
