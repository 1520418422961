.admin-autocomplete {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    //padding: 7px 16px !important;
    border-radius: 6px;
    border: 1px solid var(--gray-2);
    background: var(--gray-3);
    height: auto;

    input {
      height: auto;
      padding: 10px 0;
      color: var(--text-color-1);
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

.ant-select-selection-search-input {
  padding: 10px 4px !important;
}

.ant-select-selection-placeholder {
  font-weight: 500;
  padding: 10px 4px !important;
  color: var(--text-color-2);
}

.ant-select-selection__placeholder {
  color: blue !important;
}

.autocomplete-dropdown {
  background: var(--gray-3);
  border-radius: 6px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);

  .ant-select-item {
    color: var(--text-color-1);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}
