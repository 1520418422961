.ant-breadcrumb {
  ol {
    li {
      a,
      span {
        color: var(--text-color-2);
      }
      &:last-child {
        a,
        span {
          color: var(--last-crumb);
        }
      }
    }
  }
}
