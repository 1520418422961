.h-ultra {
  font-size: 96px;
  font-weight: 700;
  line-height: 120px;
}

h1 {
  font-size: 48px;
  font-weight: 700;
  line-height: 60px;
}

h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
}

h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
}

h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

.text-18 {
  font-size: 18px;
  line-height: 28px;
}

.text-16 {
  font-size: 16px;
  line-height: 24px;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

a {
  outline: none;
  text-decoration: none;
  transition: var(--default-transition);

  &:hover {
    color: var(--primary);
    transition: var(--default-transition);
  }
}

@media screen and (max-width: 1140px) {
  .h-ultra {
    font-size: 48px;
  }

  h1 {
    font-size: 32px;
    line-height: 40px;
  }

  h2 {
    font-size: 28px;
    line-height: 42px;
  }

  h3 {
    font-size: 24px;
    line-height: 36px;
  }

  h4 {
    font-size: 20px;
    line-height: 32px;
  }
}

@media screen and (max-width: 590px) {
  h4 {
    font-size: 16px;
    line-height: 24px;
  }

  h2 {
    font-size: 24px !important;
    line-height: 36px !important;
  }
  h3 {
    font-size: 18px !important;
    line-height: 28px !important;
  }
}
