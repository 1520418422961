.primary-color {
  color: var(--primary) !important;
}

.success-color {
  color: var(--success) !important;
}

.danger-color {
  color: var(--danger) !important;
}

.muted-color {
  color: var(--text-color-2) !important;
}

.text-color-1 {
  color: var(--text-color-1) !important;
}
