.ant-slider {
  margin: 10px 12px;

  .ant-slider-rail {
    height: 8px;
    border-radius: 4px;
    background-color: var(--gray-border);
  }

  .ant-slider-track {
    height: 8px;
    background-color: var(--primary);
  }

  .ant-slider-handle {
    width: 24px;
    height: 24px;
    border: 4px solid #fff;
    border-radius: 12px;
    background-color: var(--primary);
    top: 0;
  }

  &:hover {
    .ant-slider-rail {
      background-color: var(--gray-border);
    }
    .ant-slider-track {
      background-color: var(--primary);
    }
    .ant-slider-handle:not(.ant-tooltip-open) {
      border-color: #fff;
    }
  }
}
