.ant-avatar {
  color: var(--primary);
  background: var(--primary-light);
}

.ant-avatar-string {
  font-weight: 500;
}

.ant-avatar-group .ant-avatar {
  border-color: var(--primary);
}
