.ant-drawer-content {
  background: var(--body-bg);
}

.ant-drawer-close {
  color: var(--text-color-1);
}

.ant-drawer-header {
  border-color: var(--gray-border);
}

.help-center-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    max-width: 720px !important;
  }
}
