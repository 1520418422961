@import 'colors';
@import 'type';
@import 'containers';
@import 'labels';
@import 'quill';

body {
  font-family: Montserrat, sans-serif;
  color: var(--text-color-1);
}

.video-react .video-react-poster {
  background-size: cover;
}

a.as-btn {
  padding: 6px 12px;
  border-radius: 6px;
  background: var(--primary-light);
  color: var(--primary);
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #fff;
    background: var(--primary);
    transition: all 0.3s ease-in-out;
  }
}

.ps__rail-x,
.ps__rail-y {
  display: none !important;
}

.span-coma {
  span {
    &:after {
      content: ', ';
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}
