:root {
  --primary: #3b71fe;
  --primary-dark: #2b3b66;
  --primary-light: #e5ecfe;
  --secondary-hover: #eff3fe;
  --secondary-active: #d5e1fe;
  --success: #47ad45;
  --success-light: rgba(71, 173, 69, 0.1);
  --success-colrized-card: #47be7d;
  --orange-rate: #ff9f2e;
  --danger: #fe6e6d;
  --danger-light: rgba(254, 110, 109, 0.1);

  --text-color-1: #000;
  --text-color-2: #808080;
  --text-color-1-inverse: #fff;
  --rounded-btn-icon: #000;
  --top-stories-text-color: #fe6e6d;
  --top-stories-text-color-light: rgba(254, 110, 109, 0.1);
  --properties-text-color: #47ad45;

  --gray-1: #f2f2f2;
  --gray-2: #e0e0e0;
  --gray-3: rgba(247, 247, 247, 1);
  --gray-4: #eff3fe;
  --last-crumb: #ccc;
  --gray-border: #e0e0e0;
  --gray-border2: #e0e0e0;
  --gray-border-light: rgba(224, 224, 224, 0.2);

  --body-bg: #fff;
  --plan-bg: #fff;
  --plan-checkbox-bg: #fff;
  --rounded-btn-bg: #ebebeb;
  --dropdown-menu-bg: #fff;
  --review-bg: #eff3fe;
  --search-bar-bg: #fff;
  --top-stories-bg: #ffe1dd;
  --label-bg: #fff;
  --properties-bg: #e0f6e0;
  --story-card-bg: linear-gradient(117.36deg, #b0dbff 0%, #c1e3ff 96.16%);
  --showcase-bg: #e5ecfe;
  --sell-btn: #fff;

  --header-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  --category-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);

  --default-transition: all 0.3s ease-in-out;

  --admin-body-bg: #fcfcfc;
  --admin-sidebar-bg: #ffffff;
  --admin-white-bg: #ffffff;
  --admin-card-border-color: #f1f1f2;
  --admin-card-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
}

[data-theme='dark'] {
  --primary: #507ae9;
  --primary-light: #1c2743;
  --secondary-hover: #2b3b66;
  --secondary-active: #0d121f;

  --text-color-1: #ffffff;
  --text-color-2: #b3b3b3;
  --text-color-1-inverse: #000;
  --rounded-btn-icon: #ebe8e8;
  --top-stories-text-color: #ffe1dd;
  --properties-text-color: #e0f6e0;

  --gray-1: #363636;
  --gray-2: #363636;
  --gray-3: #262626;
  --gray-4: #262626;
  --last-crumb: #5c5c5c;
  --gray-border: #363636;
  --gray-border2: #363636;

  --body-bg: #1f1f1f;
  --plan-bg: #2e2e2e;
  --plan-checkbox-bg: #2b3b66;
  --rounded-btn-bg: #363636;
  --dropdown-menu-bg: #262626;
  --review-bg: #18233d;
  --search-bar-bg: #262626;
  --top-stories-bg: #fe6e6d66;
  --label-bg: #1f1f1f;
  --properties-bg: rgba(71, 173, 69, 0.4);
  --story-card-bg: linear-gradient(180deg, #403f8d 0%, #657ba0 100%);
  --showcase-bg: #181c28;
  --sell-btn: #242f4b;

  --header-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
  --category-shadow: 0 4px 16px 0 rgba(210, 207, 207, 0.12);

  --default-transition: all 0.3s ease-in-out;

  --admin-body-bg: #1f1f1f;
  --admin-sidebar-bg: #1f1f1f;
  --admin-white-bg: #262626;
  --admin-card-border-color: #363636;
  --admin-card-box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03);
}
