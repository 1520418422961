.properties-admin-form {
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px;
    border: 1px solid var(--gray-2);
    background: var(--gray-3);
  }

  .ant-select-selector,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    //padding: 0 16px !important;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 46px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
    .ant-select-selection-search-input {
    height: 44px;
    line-height: 44px;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-item,
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-placeholder {
    line-height: 44px;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color-1);
  }

  .ant-select-multiple.ant-select-lg .ant-select-selection-search {
    height: 44px;
    line-height: 44px;
  }

  .ant-select-clear {
    color: var(--text-color-1);
    background: var(--gray-3);

    .anticon {
      vertical-align: 2px;
    }
  }

  .ant-select-multiple .ant-select-selection-item {
    background-color: var(--body-bg);
    border: 1px solid var(--gray-2);
    border-radius: 6px;

    .anticon {
      vertical-align: 2px;
    }
  }

  .ant-select-multiple .ant-select-selection-item-content {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color-1);
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
    border-color: var(--primary);
  }

  .ant-select:hover .ant-select-selector {
    border-color: var(--primary);
  }

  .ant-input-number-lg {
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--gray-2);
    background: var(--gray-3);
    overflow: hidden;
  }

  .ant-input-number-lg input {
    width: 100%;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color-1);
  }

  .ant-input-number-handler-wrap {
    background: var(--gray-3);
  }

  .ant-input-number-handler {
    color: var(--text-color-1);
    border-left: 1px solid var(--gray-2);

    .anticon {
      vertical-align: 2px;
    }
  }

  .ant-input-number-handler-up-inner,
  .ant-input-number-handler-down-inner {
    color: var(--text-color-1);
  }

  .ant-input-number:hover:not(.ant-input-number-borderless) .ant-input-number-handler-down,
  .ant-input-number-focused:not(.ant-input-number-borderless) .ant-input-number-handler-down {
    border-top: 1px solid var(--gray-2);
  }

  .ant-input-number-handler-wrap:hover .ant-input-number-handler {
    height: 50%;
  }

  .ant-checkbox-wrapper {
    font-size: 16px;
    font-weight: 400;
    color: var(--text-color-1);
  }

  .ant-checkbox {
    color: var(--text-color-1);
  }

  .ant-checkbox-inner {
    width: 18px;
    height: 18px;
    background-color: var(--gray-3);
    border: 1px solid var(--gray-2);
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: var(--primary);
  }
}

.ant-select-dropdown .ant-select-item-group {
  font-size: 16px;
  font-weight: 500;
}

.ant-input-affix-wrapper {
  padding: 10px 16px;
  border-radius: 6px;
  border: 1px solid var(--gray-2);
  color: var(--text-color-1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  background: var(--gray-3);

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--gray-3) inset !important;
  }

  textarea,
  input {
    background: transparent !important;
  }

  &:hover,
  &:active,
  &:focus {
    border-color: var(--primary);
    box-shadow: none;
  }
}

.ant-checkbox-wrapper,
.ant-checkbox {
  color: var(--text-color-1);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 46px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 100%;
}
