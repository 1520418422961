.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.ant-steps {
  color: var(--text-color-1);
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  font-weight: 500;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}

.ant-steps-item-finish .ant-steps-item-icon,
.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--body-bg);
  border-color: var(--primary);
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--primary);
}

.ant-steps-item-icon {
  border: 1px solid var(--gray-border);
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: var(--body-bg);
  border-color: var(--gray-border);
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: var(--text-color-2);
}

.ant-steps-icon .anticon {
  vertical-align: 2px;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: var(--primary);
}
