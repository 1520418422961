.ant-btn {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  border-radius: 12px;
  font-size: 16px;
  line-height: 24px;
  height: auto;
  font-weight: 700;
  letter-spacing: 0.02em;
  background: var(--primary-light);
  color: var(--primary);
  border: none;
  text-transform: uppercase;

  .anticon {
    vertical-align: 2px;
  }

  &:after {
    all: unset;
  }

  > svg {
    display: block;
  }

  > span {
    display: block;
  }

  &:hover {
    background: var(--secondary-hover);
    color: var(--primary);
  }

  &:active,
  &:focus {
    background: var(--secondary-active);
    color: var(--primary);
  }
}

.ant-btn-ghost {
  padding: 0;
  margin: 0;
  background: none;
  &:hover,
  &:active,
  &:focus {
    padding: 0;
    margin: 0;
    background: none;
  }
}

.ant-btn-primary {
  background: var(--primary);
  text-transform: uppercase;
  color: #ffffff;

  &:hover {
    background: #5b88fe;
    color: #ffffff;
  }

  &:active,
  &:focus {
    background: #2f5bcc;
    color: #ffffff;
  }
}

.ant-btn-success {
  background: var(--success);
  text-transform: uppercase;
  color: #ffffff;

  &:hover {
    background: var(--success-light);
    color: var(--success);
  }

  &:active,
  &:focus {
    background: var(--success-light);
    color: var(--success);
  }
}

.ant-btn-primary__light {
  font-weight: 500 !important;
  background: var(--primary-light) !important;
  text-transform: uppercase !important;
  color: var(--primary) !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-shadow: none;

  &:hover {
    background: var(--primary) !important;
    color: #ffffff !important;
  }

  &:active,
  &:focus {
    background: var(--primary) !important;
    color: #ffffff !important;
  }
}

.ant-btn-sm {
  padding: 12px 24px;
  border-radius: 6px;
  gap: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}

.ant-btn-extra-small {
  padding: 6px 8px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
}

.ant-btn-round {
  padding: 0;
  width: 40px !important;
  height: 40px;
  border-radius: 50%;
  background: var(--rounded-btn-bg);
  color: var(--rounded-btn-icon);

  &:hover {
    background: var(--rounded-btn-bg);
    color: var(--primary);
  }
  &:focus {
    background: var(--rounded-btn-bg);
    color: var(--primary);
  }
}

.ant-btn-dangerous {
  color: var(--top-stories-text-color);
  background: var(--top-stories-text-color-light);

  &:hover,
  &:focus {
    color: #fff;
    background: var(--top-stories-text-color);
  }
}

.ant-btn-dangerous__light {
  background: transparent !important;
  border-color: transparent !important;
  color: var(--danger) !important;
  box-shadow: none !important;
}

.admin-filters-btn {
  font-size: 20px;
}

.btn-sell-faster {
  background: linear-gradient(90deg, #a32eff 1.81%, #433fff 100%);
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active {
    background: linear-gradient(90deg, #433fff 1.81%, #a32eff 100%);
    transition: all 0.3s ease-in-out;
  }
}

@media screen and (max-width: 590px) {
  .ant-btn {
    font-size: 14px;
    line-height: 20px;
  }
}
