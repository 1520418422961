.ant-rate {
  .ant-rate-star-full,
  .ant-rate-star-half {
    color: #ff9f2e;
  }

  .ant-rate-star-half {
    .ant-rate-star-second {
      color: var(--gray-border);
    }
  }

  .ant-rate-star-zero {
    * {
      color: var(--gray-border);
    }
  }
}
