.ant-pagination {
  display: flex;
  width: 304px;
  height: 40px;
  justify-content: center;
  align-items: center;
  transition: var(--default-transition);

  .ant-pagination-item-active {
    background: var(--primary-light);
    color: var(--primary);
  }

  li {
    color: var(--text-color-1);
    background: transparent;
    border: none;
    border-radius: 4px;
    display: flex;
    width: 40px;
    height: 40px;
    padding: 4px;
    justify-content: center;
    align-items: center;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;

    &:hover {
      background: var(--primary-light);
      transition: var(--default-transition);
    }
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border: none;
    background: transparent;
    color: var(--text-color-1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
