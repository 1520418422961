.ant-input {
  padding: 10px 16px;
  border-radius: 6px;
  border: 1px solid var(--gray-2);
  color: var(--text-color-1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  background: var(--gray-3);
}

.ant-input-lg {
  padding: 10px 16px;
}

.ant-input::placeholder {
  color: var(--text-color-2);
}

.ant-input:focus,
.ant-input-focused,
.ant-input:hover {
  border-color: var(--primary);
  box-shadow: none;
}

.ant-input-status-error {
  background: var(--gray-3) !important;
}

.stripe-input-wrapper {
  border: 1px solid var(--gray-2);
  background: var(--gray-3);
  padding: 10px 16px;
  border-radius: 6px;
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover {
  background: var(--gray-3);
  border-color: var(--danger);
}

.ant-form-item-with-help .ant-form-item-explain {
  position: absolute !important;
  color: var(--text-color-1) !important;
  background: transparent !important;

  .ant-form-item-explain-error {
    color: var(--text-color-1) !important;
    background: transparent !important;
  }
}
