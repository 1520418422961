.ant-popover {
  color: var(--text-color-1);
}

.ant-popover-inner {
  background-color: var(--body-bg);
  border-radius: 6px;
  box-shadow: var(--header-shadow);
}

.ant-popover-arrow-content {
  --antd-arrow-background-color: var(--body-bg);
}

.ant-popover-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    display: block;
    padding: 4px 8px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
  }
}

.ant-popover-message {
  .ant-popover-message-title {
    font-size: 16px;
    line-height: 22px;
    color: var(--text-color-1);
  }

  .anticon {
    vertical-align: 2px;
  }
}
