.ant-collapse {
  * {
    color: var(--text-color-1);
  }

  .ant-collapse-item {
    border-bottom: 1px solid var(--gray-2);

    &:last-child {
      border-bottom: 1px solid var(--gray-2);
      &.ant-collapse-item-active {
        border-bottom: none;
      }
    }

    .ant-collapse-header {
      padding: 32px;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0;
    }

    .ant-collapse-content {
      padding: 0;

      .ant-collapse-content-box {
        width: 100%;
        padding: 16px 0;
        border-radius: 8px;
        background: var(--gray-3);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: center;

        > ul {
          > li {
            padding: 12px 0;

            a {
              &:hover {
                color: var(--primary);
              }
            }
          }
        }
      }
    }
  }

  .ant-collapse-item-active {
    border-bottom: none;
  }
}
