.ant-progress-bg {
  background-color: orange;
}

.ant-progress-inner {
  background: var(--gray-4);
}

.subscription-progress {
  .ant-progress-bg {
    background: var(--primary);
  }
}
